import React, {Component} from 'react';
import {graphql} from 'gatsby';

import SEO from '../components/shared/SEO';
import Navbar from '../components/shared/Navbar';
import Footer from '../components/shared/Footer';
import styles from './404.module.scss';
import registerIcons from '../styles/registerIcons';

// font awesome library needs to be created via entry point of app, in gatsby apps that's index.js
registerIcons();

class fourOhFour extends Component {
  render() {
    const {data} = this.props;
    const {markdownRemark} = data;
    const {html} = markdownRemark;

    return (
      <div>
        <header>
          <SEO />
          <Navbar />
        </header>
        <main>
          <div className={styles.background}>
            <div className={styles.navBackground}></div>
            <div className={'row'}>
              <div className={'col-md-2'}></div>
              <div className={'offset-md-2' + ' ' + styles.textArea} dangerouslySetInnerHTML={{__html: html}} />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/404-page/" } }) {
      html
    }
  }
`;

export default fourOhFour;
